import { ReactElement } from "react";

function Notes(): ReactElement {
    return (
        <div style={{ textAlign: "center" }}>
            <h1>YOUR NOTES</h1>
        </div>
    );
}

export default Notes;